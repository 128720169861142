import React, { Component } from 'react'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import './scss/style.scss'
import axios from 'axios'
import Login from './views/pages/login/Login'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'))

// Pages
// const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const refreshAuthLogic = (failedRequest) =>
  axios
    .post(`http://tdiu.academytable.ru/token-auth/`, {
      username: localStorage.getItem('username'),
      password: localStorage.getItem('password'),
    })
    .then((response) => response.data.token)
    .then((data) => {
      localStorage.setItem('token', data)
      console.log(data)
    })

// const refreshAuthLogic = (failedRequest) =>
//   axios
//     .post(
//       `http://tdiu.academytable.ru/token-auth/`,
//       {
//         username: localStorage.getItem('username'),
//         password: localStorage.getItem('password'),
//       },
//       { headers: { Authorization: `JWT ${localStorage.getItem('token')}` } },
//     )
//     .then((res) => res.json())
//     .then((json) => {
//       localStorage.setItem('token', json.token)
//       localStorage.setItem('username', json.user.username)
//       localStorage.setItem('password', json.user.password)
//       this.setState({
//         logged_in: true,
//         displayed_form: '',
//       })
//       console.log(this.state.group)
//       return Promise.resolve()
//     })
//     .catch((err) => alert('Error'))

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      displayed_form: '',
      logged_in:
        localStorage.getItem('token') &&
        localStorage.getItem('token') !== 'undefined'
          ? true
          : false,
      group: '',
    }
  }

  componentDidMount() {
    createAuthRefreshInterceptor(axios, refreshAuthLogic)
    if (this.state.logged_in) {
      fetch('http://tdiu.academytable.ru/core/current_user/', {
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`,
        },
      })
        .then((res) => res.json())
        .then((json) => {
          localStorage.setItem('token', json.token)
          this.setState({
            logged_in: true,
          })
          console.log(this.state.group)
        })
    }

    axios
      .post(`http://tdiu.academytable.ru/token-auth/`, {
        username: localStorage.getItem('username'),
        password: localStorage.getItem('password'),
      })
      .then((response) => response.data.token)
      .then((data) => {
        localStorage.setItem('token', data.token)
        console.log(data)
      })
  }

  handle_login = (e, data) => {
    // e.preventDefault()
    fetch('http://tdiu.academytable.ru/token-auth/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((json) => {
        localStorage.setItem('token', json.token)
        localStorage.setItem('username', json.user.username)
        localStorage.setItem('password', data.password)
        this.setState({
          logged_in: true,
          displayed_form: '',
        })
        console.log(this.state.group)
      })
      .catch((err) => alert('Error'))
  }

  handle_signup = (e, data) => {
    e.preventDefault()
    fetch('http://tdiu.academytable.ru/core/users/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((json) => {
        localStorage.setItem('token', json.token)
        this.setState({
          logged_in: true,
          displayed_form: '',
          username: json.username,
        })
      })
  }

  handle_logout = () => {
    localStorage.removeItem('token')
    this.setState({ logged_in: false, username: '' })
  }
  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            {this.state.logged_in ? (
              <Route
                path="/"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
            ) : (
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => <Login handle_login={this.handle_login} />}
              />
            )}

            {this.state.logged_in ? (
              <Redirect to="/subjects" />
            ) : (
              <Redirect to="/login" />
            )}

            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    )
  }
}

export default App
