import React from 'react'
import { Link } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import PropTypes from 'prop-types'

class Login extends React.Component {
  state = {
    username: localStorage.getItem('username'),
    password: localStorage.getItem('password'),
  }

  handle_change = (e) => {
    const name = e.target.name
    const value = e.target.value
    this.setState((prevstate) => {
      const newState = { ...prevstate }
      newState[name] = value
      return newState
    })
  }
  render() {
    return (
      <div className="c-app c-default-layout flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md="5">
              <CCardGroup>
                <CCard className="p-3">
                  <CCardBody>
                    <CForm>
                      <h1>Логин</h1>
                      <p className="text-muted">Войдите в свой аккаунт</p>
                      <CInputGroup className="mb-3">
                        <CInputGroupPrepend>
                          <CInputGroupText>
                            <CIcon name="cil-user" />
                          </CInputGroupText>
                        </CInputGroupPrepend>
                        <input
                          type="text"
                          name="username"
                          value={this.state.username}
                          onChange={this.handle_change}
                          id="username"
                          class="form-control"
                          placeholder="Login"
                        ></input>
                      </CInputGroup>
                      <CInputGroup className="mb-4">
                        <CInputGroupPrepend>
                          <CInputGroupText>
                            <CIcon name="cil-lock-locked" />
                          </CInputGroupText>
                        </CInputGroupPrepend>
                        <input
                          type="text"
                          type="password"
                          name="password"
                          value={this.state.password}
                          onChange={this.handle_change}
                          id="password"
                          class="form-control"
                          placeholder="Password"
                        ></input>
                      </CInputGroup>
                      <CRow>
                        <CCol xs="6">
                          <button
                            onClick={(e) =>
                              this.props.handle_login(e, this.state)
                            }
                            type="button"
                            class="btn btn-primary"
                          >
                            Login
                          </button>
                        </CCol>
                        <CCol xs="6" className="text-right">
                          <CButton color="link" className="px-0">
                            Забыли пароль?
                          </CButton>
                        </CCol>
                      </CRow>
                    </CForm>
                  </CCardBody>
                </CCard>
                {/* <CCard className="text-white bg-primary py-5 d-md-down-none" style={{ width: '44%' }}>
                <CCardBody className="text-center">
                  <div>
                    <h2>Sign up</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                      labore et dolore magna aliqua.</p>
                    <Link to="/register">
                      <CButton color="primary" className="mt-3" active tabIndex={-1}>Register Now!</CButton>
                    </Link>
                  </div>
                </CCardBody>
              </CCard> */}
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    )
  }
}

export default Login

Login.propTypes = {
  handle_login: PropTypes.func.isRequired,
}
